import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import BarChart from "../../components/charts/BarChart";
import ProcessingTimeChart from '../../components/charts/ProcessingTimeChart';
import AccuracyChart from '../../components/charts/accuracyChart';
import {useNavbar} from '../../components/Navbar/NavbarContext';
import ExploreButton from '../../components/exploreMoreButtons';
import ProcessCount from '../../components/charts/CountChart/ProcessingCount';

const Home = () => {
    const navigate = useNavigate();
    const {setCollapsed} = useNavbar();
    setCollapsed(false);
    return (
        <div>
            <h2 style={{color: '#674f87', fontFamily: 'sans-serif',}}>
                Welcome to AiChemist
            </h2>
            <div style={{display: 'flex', gap: '40px', padding: '10px'}}>
                <AccuracyChart value={90}></AccuracyChart>
                <ProcessingTimeChart value={50000} totalValue={70000}></ProcessingTimeChart>
                <BarChart></BarChart>
                <ProcessCount value={9565189}></ProcessCount>
            </div>
            <div style={{marginTop: '300px', marginLeft: '10px'}}>
                <ExploreButton/>
            </div>
        </div>
    )
}

export default Home

