import React, {useState, useEffect, useRef} from 'react';
import {useDropzone} from 'react-dropzone';
import axios from 'axios';
import uploadIcon from './icons/Upload.svg';
import FolderZipIcon from '@mui/icons-material/FolderZip';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import CloseIcon from '@mui/icons-material/Close';
import api from '../../API';
import {useNavigate} from 'react-router-dom';

const DropBox = ({setActiveTab}) => {
    const [files, setFiles] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [popupVisible, setPopupVisible] = useState(false);
    const [showDropzone, setShowDropzone] = useState(true);
    const navigate = useNavigate();

    const popupRef = useRef(null);

    const onDrop = (acceptedFiles) => {
        setFiles((prevFiles) =>
            prevFiles.concat(
                acceptedFiles.map((file) =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    })
                )
            )
        );
        setShowDropzone(false);
    };

    const handleFileClick = (file) => {
        setSelectedFile(file);
        setPopupVisible(true);
    };

    const handleDelete = () => {
        setFiles((prevFiles) => prevFiles.filter((file) => file !== selectedFile));
        setPopupVisible(false);
    };

    const handleReselectFiles = () => {
        setFiles([]);
        setShowDropzone(true);
    };

    const handleClickOutside = (event) => {
        if (popupRef.current && !popupRef.current.contains(event.target)) {
            setPopupVisible(false);
        }
    };

    // const uploadFiles = async () => {
    //   const formData = new FormData();
    //   files.forEach((file) => {
    //     formData.append('files', file);
    //   });

    //   try {
    //     const response = await api.post(`data/document_upload`, formData,{'Content-Type': 'multipart/form-data'});
    //     console.log('File upload response:', response.data);
    //     alert('Files uploaded successfully');

    //     // Clear selected data
    //     setFiles([]);
    //     setShowDropzone(true);

    //     // Navigate to tab 1
    //     // setActiveTab(1);  // Assuming setActiveTab is a prop passed to change the active tab

    //   } catch (error) {
    //     console.error('Error uploading files:', error);
    //     alert(`Error uploading files: ${error.message}`);
    //   }

    // };
    const uploadFiles = async () => {
        console.log("Files:", files)
        const fileData = files.map((file) => {
            console.log("File:", file)
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = () => resolve({name: file.name, type: file.type, data: reader.result});
                reader.onerror = reject;
                reader.readAsDataURL(file);
            });
        });

        try {
            const filesAsJson = await Promise.all(fileData);

            const response = await api.post('data/document_upload', {
                files: filesAsJson,
            });

            console.log('File upload response:', response);
            alert('Files uploaded successfully');

            // Clear selected data
            setFiles([]);
            setShowDropzone(true);

        } catch (error) {
            console.error('Error uploading files:', error);
            alert(`Error uploading files: ${error.message}`);
        }
    };


    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const {getRootProps, getInputProps} = useDropzone({
        onDrop,
        accept: {
            'image/png': [],
            'image/jpg': [],
            'image/jpeg': [],
            'image/bmp': [],
            'image/gif': [],
            'image/webp': [],
            'application/zip': [],
            'application/pdf': [],
        },
    });

    const renderDropzone = () => (
        <div
            {...getRootProps({className: 'dropzone'})}
            style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
            }}
        >
            <input {...getInputProps()} />
            <img
                src={uploadIcon}
                alt="Upload"
                style={{
                    width: '150px',
                    height: '150px',
                    paddingLeft: '10px',
                }}
            />
            <p style={{alignItems: 'center', padding: '0px'}}>
                Drag and drop or click to select files
            </p>
        </div>
    );

    const handleCloseClick = () => {
        navigate('/documents'); // Navigate to Dashboard page
    };

    const renderFiles = () => (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '100%',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '10px',
                    padding: '10px',
                }}
            >
                {files.map((file) => (
                    <div
                        key={file.name}
                        style={{
                            marginBottom: '10px',
                            textAlign: 'center',
                            position: 'relative',
                        }}
                        onClick={() => handleFileClick(file)}
                    >
                        {file.type === 'application/zip' ? (
                            <div
                                style={{
                                    width: '100px',
                                    height: '100px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderRadius: '10px',
                                    border: '1px solid #ccc',
                                    position: 'relative',
                                }}
                            >
                                <FolderZipIcon style={{fontSize: '98px', color: '#674f87'}}/>
                                <div
                                    ref={popupRef}
                                    style={{
                                        position: 'absolute',
                                        bottom: '0',
                                        left: '50%',
                                        transform: 'translateX(-50%)',
                                        backgroundColor: '#fff',
                                        padding: '2px 5px',
                                        borderRadius: '3px',
                                        border: '1px solid #ccc',
                                        fontSize: '12px',
                                        display: popupVisible ? 'block' : 'none',
                                    }}
                                    className="file-name"
                                >
                                    {file.name}
                                </div>
                            </div>
                        ) : file.type === 'application/pdf' ? (
                            <div
                                style={{
                                    width: '100px',
                                    height: '100px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderRadius: '10px',
                                    border: '1px solid #ccc',
                                    position: 'relative',
                                }}
                            >
                                <PictureAsPdfIcon style={{fontSize: '98px', color: '#674f87'}}/>
                                <div
                                    ref={popupRef}
                                    style={{
                                        position: 'absolute',
                                        bottom: '0',
                                        left: '50%',
                                        transform: 'translateX(-50%)',
                                        backgroundColor: '#fff',
                                        padding: '2px 5px',
                                        borderRadius: '3px',
                                        border: '1px solid #ccc',
                                        fontSize: '12px',
                                        display: popupVisible ? 'block' : 'none',
                                    }}
                                    className="file-name"
                                >
                                    {file.name}
                                </div>
                            </div>
                        ) : (
                            <img
                                src={file.preview}
                                alt={file.name}
                                style={{
                                    width: '100px',
                                    height: '100px',
                                    objectFit: 'cover',
                                    borderRadius: '10px',
                                }}
                            />
                        )}
                    </div>
                ))}
            </div>
            <div
                style={{
                    display: 'flex',
                    gap: '10px',
                    justifyContent: 'flex-end',
                    padding: '10px',
                    position: 'sticky',
                    bottom: '0',
                    backgroundColor: '#fff',
                }}
            >
                <button
                    onClick={handleReselectFiles}
                    style={{
                        padding: '10px 12px',
                        color: '#fff',
                        background: 'linear-gradient(to right, #ef798a, #674f87)',
                        borderRadius: '5px',
                        border: '1px solid #f1f1f1',
                        cursor: 'pointer',
                    }}
                >
                    Reselect Files
                </button>
                <button
                    onClick={() => uploadFiles(window.globalVar1)}
                    style={{
                        padding: '10px 12px',
                        color: '#fff',
                        background: 'linear-gradient(to right, #ef798a, #674f87)',
                        borderRadius: '5px',
                        border: '1px solid #f1f1f1',
                        cursor: 'pointer',
                    }}
                >
                    Upload
                </button>
            </div>
        </div>
    );

    return (
        <div
            style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                border: '2px solid #674f87',
                paddingTop: '20px',
                paddingBottom: '20px',
                margin: 'auto',
                borderRadius: '30px',
                width: '55%',
                background: 'linear-gradient(to right, #ef798a, #674f87)',
                justifyContent: 'center'
            }}
        >
            <CloseIcon onClick={handleCloseClick} style={{
                fontSize: '30px',
                color: '#fff',
                position: 'fixed',
                right: '6px',
                top: '6px',
                cursor: 'pointer'
            }}/>
            <div
                style={{
                    border: '2px solid #ef798a',
                    cursor: 'pointer',
                    width: '94%',
                    height: '460px',
                    borderRadius: '30px',
                    margin: 'auto',
                    backgroundColor: '#fff',
                    overflow: 'auto',
                    position: 'relative',
                }}
            >
                {showDropzone ? renderDropzone() : renderFiles()}
            </div>
            {popupVisible && (
                <div
                    ref={popupRef}
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        backgroundColor: '#fff',
                        padding: '20px',
                        boxShadow: '0 0 10px rgba(0,0,0,0.9)',
                        zIndex: 1000,
                        borderRadius: '10px',
                        maxWidth: '200px',
                        maxHeight: '200px',
                        overflow: 'hidden',
                    }}
                >
                    <p title={selectedFile.name}>{selectedFile.name}</p>
                    <button
                        onClick={handleDelete}
                        style={{
                            padding: '10px 12px',
                            color: '#fff',
                            background: 'linear-gradient(to right, #ef798a, #674f87)',
                            borderRadius: '5px',
                            border: '1px solid #f1f1f1',
                            cursor: 'pointer',
                        }}
                    >
                        Delete
                    </button>
                    <button
                        onClick={() => setPopupVisible(false)}
                        style={{
                            padding: '10px 12px',
                            color: '#fff',
                            background: 'linear-gradient(to right, #ef798a, #674f87)',
                            borderRadius: '5px',
                            border: '1px solid #f1f1f1',
                            cursor: 'pointer',
                        }}
                    >
                        Close
                    </button>
                </div>
            )}
        </div>
    );
};

export default DropBox;
