import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import GradientIcon from "../../pages/AR-Dashboard/grad-icon";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DownloadIcon from "@mui/icons-material/Download";
import Delete from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import ButtonGroup from "@mui/material/ButtonGroup";


const DocumentThumbnail = (documentArgs) => {
    const truncateName = (name, length) => {
        return name.length > length ? `${name.substring(0, length)}...` : name;
    };
    return (
        <Card key={documentArgs.document.document_id}
              sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  background: '#f1f1f1',
                  transition: 'box-shadow 0.3s ease-in-out',
                  ':hover': {boxShadow: 5,}
              }}>
            <CardMedia
                sx={{
                    backgroundImage: `${documentArgs.document.document_thumbnail ? `url(${documentArgs.document.document_thumbnail})` : ('')}`,
                    width: '90%',
                    height: 150,
                    backgroundSize: 'cover',
                    cursor: 'pointer',
                    marginTop: 2,
                }}
                onClick={() => documentArgs.handleItem(documentArgs.document)}
            />
            <CardContent sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: 1
            }}>
                <Typography variant="subtitle2" title={documentArgs.document.document_sub_name}>
                    {truncateName(documentArgs.document.document_sub_name, 20)}
                </Typography>
                <Typography variant="body2" color="text.secondary">

                </Typography>
            </CardContent>
            <CardActions>
                <ButtonGroup aria-label="Basic button group" color="secondary"
                             size='small'
                             sx={{
                                 background: '#fafafa',
                                 marginBottom: '8px',
                             }}
                >
                    <Button
                        onClick={() => documentArgs.handleItem(documentArgs.document)}
                        disabled={!['user', 'maintainer', 'admin'].some(userRole => documentArgs.role.includes(userRole))}
                    >
                        <GradientIcon IconComponent={VisibilityIcon}/>
                    </Button>
                    <Button
                        onClick={() => documentArgs.downloadPdf(documentArgs.document)}
                        disabled={!['admin', 'maintainer'].some(userRole => documentArgs.role.includes(userRole))}
                    >
                        <GradientIcon IconComponent={DownloadIcon}/>
                    </Button>
                    <Button
                        onClick={documentArgs.handleDeleteOpen}
                        disabled={!['admin'].some(userRole => documentArgs.role.includes(userRole))}
                    >
                        <GradientIcon IconComponent={Delete}/>
                    </Button>
                </ButtonGroup>
                <React.Fragment>
                    <Dialog
                        open={documentArgs.deleteOpen}
                        onClose={documentArgs.handleDeleteClose}
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogContent>
                            <DialogContentText
                                id="alert-dialog-description">
                                Are you sure you want to delete this file?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={documentArgs.handleDeleteClose}>No</Button>
                            <Button onClick={() => documentArgs.deleteDocument(documentArgs.document)}
                                    autoFocus>Yes</Button>
                        </DialogActions>
                    </Dialog>
                </React.Fragment>
            </CardActions>
        </Card>
    );
};

export default DocumentThumbnail;