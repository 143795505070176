import React, {useState, useEffect} from 'react';
import Navbar from './components/Navbar/Navbar';
import {Outlet, useNavigate} from "react-router-dom";
import {useLocation} from 'react-router-dom';
import TopMenu from './components/TopMenu/TopMenu';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import Login from './pages/Login/Login';
import useTokenExpirationCheck from './hooks/TokenExpirationCheck';

function App({isLoggedIn, handleLogin}) {
    const navigate = useNavigate();
    // const [isLoggedIn, setIsLoggedIn] = useState(
    //     localStorage.getItem('isLoggedIn') === 'true'
    // );

    useTokenExpirationCheck();
    const [title, setTitle] = useState(null);
    const location = useLocation();

    useEffect(() => {
        const parsedTitle = location.pathname.replace(/\W/g, ' ');
        setTitle(parsedTitle);
    }, [location]);

    useEffect(() => {
        if (!isLoggedIn) {
            navigate('/login');
        }
    }, [isLoggedIn, navigate]);

    // const handleLogin = () => {
    //     setIsLoggedIn(true);
    // };

    return (
        <div>
            {isLoggedIn ? (
                <Box sx={{display: 'flex'}}>
                    <CssBaseline/>
                    <TopMenu/>
                    <Navbar/>
                    <Box component="main" sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 1,
                        paddingLeft: 10,
                        height: 'calc(100vh - 50px)',
                        width: 'calc(100vw - 10px)',
                    }}>
                        <Toolbar/>
                        <Outlet/>
                    </Box>
                </Box>
            ) : (
                <Login onLogin={handleLogin} />
            )}
        </div>
    );
}

export default App;
