import React, {useState} from 'react';
import FolderIcon from '@mui/icons-material/Folder';
import GradientIcon from './grad-icon';
import {Box, Button, Tooltip, styled, tooltipClasses} from '@mui/material';
import ViewCompactIcon from '@mui/icons-material/ViewCompact';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import DownloadIcon from '@mui/icons-material/Download';
import VisibilityIcon from '@mui/icons-material/Visibility';
import potb from '../images/potb.png'
import invtb from '../images/invtb.jpeg'
import judtb from '../images/judtb.png'
import ButtonGroup from '@mui/material/ButtonGroup';
import {Icon, Divider} from '@mui/material';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import GridWrapper from '../../components/common/GridWrapper/GridWrapper';
import Delete from '@mui/icons-material/Delete';
import {useAuth} from "../../contexts/AuthContext";
import api from "../../API";
import DocumentThumbnail from "../../components/Document/documentThumbnail";


const organizeData = (images) => {
    const data = {};

    images.forEach(image => {
        if (!data[image.document_type]) {
            data[image.document_type] = {};
        }
        if (!data[image.document_type][image.document_super_name]) {
            data[image.document_type][image.document_super_name] = {};
        }
        if (!data[image.document_type][image.document_super_name][image.document_sub_name]) {
            data[image.document_type][image.document_super_name][image.document_sub_name] = [];
        }
        data[image.document_type][image.document_super_name][image.document_sub_name].push(image);
    });

    return data;
};

const CustomTooltip = styled(({className, ...props}) => (
    <Tooltip {...props} classes={{popper: className}}/>
))(({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#d8d8d8',
        color: '#674f87',
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}));


const Folder = ({name, children, onDoubleClick}) => {
    const [open, setOpen] = useState(false);

    const handleDoubleClick = () => {
        setOpen(!open);
        onDoubleClick && onDoubleClick();
    };

    const truncateName = (name, length) => {
        return name.length > length ? `${name.substring(0, length)}...` : name;
    };


    return (
        <Box
            onDoubleClick={handleDoubleClick}
            sx={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                padding: '1px',
                borderRadius: '4px',
            }}
        >
            <CustomTooltip title={name}>
                <Box
                    sx={{
                        display: 'flex',
                        width: '200px',
                        alignItems: 'center',
                        borderRadius: '4px',
                        '&:hover': {backgroundColor: '#fcdce0',},
                    }}
                >
                    <FolderIcon sx={{color: '#ef798a', fontSize: '64px'}}/>
                    <Typography
                        variant="body1"
                        sx={{
                            // color: '#674f87', 
                            marginInline: '8px',
                            fontWeight: 'bold',
                            textAlign: 'left',
                            maxWidth: '120px',
                            wordWrap: 'break-word',
                            whiteSpace: 'normal',
                            maxHeight: '4em',
                            overflowY: 'auto',
                        }}
                    >
                        {truncateName(name, 12)}
                    </Typography>
                </Box>
            </CustomTooltip>
            <Box sx={{display: open ? 'block' : 'none'}}>
                {children}
            </Box>
        </Box>


    );
};

const FileManager = ({
                         docs,
                         downloadPdf,
                         handleItem,
                         handleDeleteOpen,
                         deleteOpen,
                         handleDeleteClose,
                         deleteDocument
                     }) => {
    const {user, role} = useAuth();
    const [images, setImageUrlIp] = useState(docs);
    const data = organizeData(images);
    const [currentPath, setCurrentPath] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');

    const navigateTo = (path) => {
        setCurrentPath(path);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const renderContents = (data, path = []) => {
        let filteredData = data;

        // Filter images based on selected tags
        if (selectedTags.length > 0) {
            filteredData = Object.keys(data).reduce((acc, id) => {
                const filteredDocTypes = {};
                Object.keys(data[id]).forEach(docType => {
                    const filteredDocs = {};
                    Object.keys(data[id][docType]).forEach(docName => {
                        const filteredFiles = data[id][docType][docName].filter(file => {
                            return selectedTags.some(tag => file.tags.includes(tag));
                        });
                        if (filteredFiles.length > 0) {
                            filteredDocs[docName] = filteredFiles;
                        }
                    });
                    if (Object.keys(filteredDocs).length > 0) {
                        filteredDocTypes[docType] = filteredDocs;
                    }
                });
                if (Object.keys(filteredDocTypes).length > 0) {
                    acc[id] = filteredDocTypes;
                }
                return acc;
            }, {});
        }

        if (searchQuery) {
            const query = searchQuery.toLowerCase();
            filteredData = Object.keys(filteredData).reduce((acc, id) => {
                const matchingFiles = [];

                if (id.toString().toLowerCase().includes(query)) {
                    Object.keys(filteredData[id]).forEach(docType => {
                        Object.keys(filteredData[id][docType]).forEach(docName => {
                            matchingFiles.push(...filteredData[id][docType][docName]);
                        });
                    });
                }

                if (matchingFiles.length > 0) {
                    acc[id] = {'': {'': matchingFiles}};
                }
                return acc;
            }, {});
        }
        // Render based on the filtered data
        if (path.length === 0) {
            return Object.keys(filteredData).map((id) => (
                <Folder
                    key={id}
                    name={`${id}`}
                    onDoubleClick={() => navigateTo([id])}
                />
            ));
        } else if (path.length === 1) {
            const id = path[0];
            return Object.keys(filteredData[id]).map((docType) => (
                <Folder
                    key={docType}
                    name={docType}
                    onDoubleClick={() => navigateTo([id, docType])}
                />
            ));
        } else if (path.length === 2) {
            const id = path[0];
            const docType = path[1];
            return Object.keys(filteredData[id][docType]).map((docName) => (
                <Folder
                    key={docName}
                    name={docName}
                    onDoubleClick={() => navigateTo([id, docType, docName])}
                />
            ));
        } else if (path.length === 3) {
            const id = path[0];
            const docType = path[1];
            const docName = path[2];
            return filteredData[id][docType][docName].map((file) => (
                <DocumentThumbnail
                    key={file.document_id}
                    role={role}
                    document={file}
                    handleItem={handleItem}
                    downloadPdf={downloadPdf}
                    handleDeleteOpen={handleDeleteOpen}
                    deleteOpen={deleteOpen}
                    handleDeleteClose={handleDeleteClose}
                    deleteDocument={deleteDocument}
                />
            ));
        }
    };

    const [selectedTags, setSelectedTags] = React.useState([]);


    function handleBreadCrumb(event) {
        event.preventDefault();
        console.info('You clicked a breadcrumb.');
    }

    const truncateName = (name, length) => {
        return name.length > length ? `${name.substring(0, length)}...` : name;
    };

    return (
        <GridWrapper sx={{paddingTop: '10px'}}>

            <Box sx={{
                marginTop: 2,
                marginBottom: 1,
                display: 'flex',
                alignItems: 'center',
                gap: 4,
                width: '80%',
                background: '#ffffff'
            }}
            >

                {currentPath.length > 0 && (
                    <Button variant="outlined" size="small" onClick={() => setCurrentPath(currentPath.slice(0, -1))}
                            color="secondary" startIcon={<GradientIcon IconComponent={ArrowLeftIcon}/>}>
                        Back
                    </Button>
                )}

                {currentPath.length > 0 && (
                    <div onClick={handleBreadCrumb}>
                        <Breadcrumbs aria-label="breadcrumb" sx={{fontWeight: 'bold', color: '#ef798a',}}>
                            <Link
                                underline="hover"
                                color="inherit"
                                onClick={() => setCurrentPath([])}
                                sx={{
                                    fontWeight: 'bold',
                                    color: '#674f87',
                                    '&:hover': {color: '#ef798a',},
                                    cursor: 'pointer',
                                    margin: '0 8px'
                                }}
                            >
                                Documents
                            </Link>
                            {currentPath.map((path, index) => (
                                index < currentPath.length - 1 ? (
                                    <CustomTooltip title={path}>
                                        <Link
                                            underline="hover"
                                            color="inherit"
                                            key={path}
                                            onClick={() => setCurrentPath(currentPath.slice(0, index + 1))}
                                            sx={{
                                                fontWeight: 'bold',
                                                color: '#674f87',
                                                '&:hover': {color: '#ef798a',},
                                                cursor: 'pointer',
                                                margin: '0 8px',
                                                // maxWidth: '150px', // Adjust the width as needed
                                                // overflow: 'hidden',
                                                // textOverflow: 'ellipsis',
                                                // whiteSpace: 'nowrap',
                                            }}
                                        >
                                            {truncateName(path, 12)}
                                        </Link>
                                    </CustomTooltip>
                                ) : (
                                    <CustomTooltip title={path}>
                                        <Typography
                                            color="text.primary"
                                            key={path}
                                            sx={{
                                                fontWeight: 'bold',
                                                color: '#674f87',
                                                // maxWidth: '150px', // Adjust the width as needed
                                                // overflow: 'hidden',
                                                // textOverflow: 'ellipsis',
                                                // whiteSpace: 'nowrap',
                                            }}
                                        >
                                            {truncateName(path, 12)}
                                        </Typography>
                                    </CustomTooltip>
                                )
                            ))}
                        </Breadcrumbs>
                    </div>
                )}
            </Box>

            <Divider/>

            <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 1, overflowInline: 'hidden', marginTop: 2}}>
                {renderContents(data, currentPath)}
            </Box>
        </GridWrapper>
    );
};

export default FileManager;
