import React, {useState, useEffect} from 'react';
import {
    Box,
    Tabs,
    Tab,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TableFooter, Select, MenuItem, CircularProgress
} from '@mui/material';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import ImportModal from '../../components/Modal/ImportModal';
import OpenModalButton from '../../components/Buttons/OpenModalButton';
import Pagination from "../../components/Pagination/Pagination";
import api from "../../API";
import FormControl from "@mui/material/FormControl";
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';

const theme = createTheme({
    components: {
        MuiTab: {
            styleOverrides: {
                root: {
                    '&.Mui-selected': {
                        color: '#b74770', // Change this to your tab color
                    },
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                indicator: {
                    backgroundColor: '#b74770', // Change this to your tab indicator color
                },
            },
        },
    },
});

const TabPanel = (props) => {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
};

const a11yProps = (index) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
};

const createParams = (limit, currentPage, status = null, label = null) => {
    const params = {};
    params.limit = limit;
    params.status = status;
    params.label = label;
    params.offset = (currentPage - 1) * limit;
    return params;
};


const Status = () => {
    const [value, setValue] = useState(0);
    const [selectedClassificationId, setSelectedClassificationId] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleFileClick = (classificationId) => {
        setSelectedClassificationId(classificationId);
        setValue(1);
    };

    const handleOpenModal = () => {
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };
    const clearSelectedId = () => {
        setSelectedClassificationId(null);
    }

    return (
        <div>
            <h2 style={{color: '#674f87', fontFamily: 'sans-serif'}}>
                Import Documents
            </h2>
            <OpenModalButton handleOpen={handleOpenModal}/>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                <ThemeProvider theme={theme}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Classification Status" {...a11yProps(0)} />
                        <Tab onClick={clearSelectedId} label="Extraction Status" {...a11yProps(1)} />
                    </Tabs>
                </ThemeProvider>
            </Box>
            <TabPanel value={value} index={0}>
                <ClassificationDetails onFileClick={handleFileClick}/>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <ExtractionDetails selectedClassificationId={selectedClassificationId}/>
            </TabPanel>
            <ImportModal open={modalOpen} handleClose={handleCloseModal}/>
        </div>
    );
};


const ClassificationDetails = (totalArgs) => {
    const [hoveredRow, setHoveredRow] = useState(null);
    const [limit, setLimit] = useState(15);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [classificationDetails, setClassificationDetails] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [classificationFilter, setClassificationFilter] = useState('');

    useEffect(() => {
        const apiCaller = async () => {
            setIsLoading(true);
            const params = createParams(limit, currentPage, classificationFilter);
            const response = await api.get('data/classification', {params});
            setIsLoading(false);

            if (response.status !== 200) {
                console.error('Files loading error', response.status);
                return false;
            }
            const responseData = response.data;
            setTotalPages(responseData.count);
            setClassificationDetails(responseData.results);
            return true;
        };
        apiCaller();
    }, [limit, currentPage, classificationFilter]);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleLimitChange = (event) => {
        setLimit(Number(event.target.value));
        setCurrentPage(1);
    };


    const handleClassificationFilterChange = (event) => {
        setClassificationFilter(event.target.value);
    };

    const filteredClassificationDetails = classificationDetails.filter((row) => {
        if (!classificationFilter) return true; // No filter applied, show all
        return row.classification_status === classificationFilter;
    });

    return (
        <div>
            <TableContainer component={Paper} sx={{height: '70vh'}}>
                <Table stickyHeader sx={{minWidth: 650}} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{fontWeight: 'bold'}}>Index</TableCell>
                            <TableCell sx={{fontWeight: 'bold'}}>File Name</TableCell>
                            <TableCell sx={{fontWeight: 'bold'}}>Upload Status</TableCell>
                            <TableCell sx={{fontWeight: 'bold'}}>
                                Classification Status
                                <FormControl variant="outlined" size="small" sx={{justifyContent: 'center'}}>
                                    <Select
                                        value={classificationFilter}
                                        onChange={handleClassificationFilterChange}
                                        displayEmpty
                                        sx={{
                                            height: '25px',
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    borderColor: '#674f87',
                                                },
                                                '&:hover fieldset': {
                                                    borderColor: '#ef798a',
                                                },
                                                '&.Mui-focused fieldset': {
                                                    borderColor: '#ef798a',
                                                },
                                            },
                                        }}
                                    >
                                        <MenuItem value="">
                                            <FilterAltOffIcon sx={{fontSize: 17, paddingTop: '2px'}}/>
                                        </MenuItem>
                                        <MenuItem value="classification_completed">Completed</MenuItem>
                                        <MenuItem value="classification_pending">Pending</MenuItem>
                                    </Select>
                                </FormControl>
                            </TableCell>
                            <TableCell sx={{fontWeight: 'bold'}}>Created At</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isLoading ? (
                            <TableRow>
                                <TableCell colSpan={5} align="center">
                                    <Box sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        height: '100%'
                                    }}>
                                        <CircularProgress/>
                                        <Typography sx={{ml: 2}}>Loading data...</Typography>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        ) : (
                            filteredClassificationDetails.map((row, index) => (
                                <TableRow
                                    key={index}
                                    sx={{height: '10%', maxHeight: '10%', padding: '5px'}}
                                >
                                    <TableCell component="th" scope="row">
                                        {index + 1}
                                    </TableCell>
                                    <TableCell
                                        style={{cursor: 'pointer', color: hoveredRow === index ? '#ef798a' : 'inherit'}}
                                        onMouseEnter={() => setHoveredRow(index)}
                                        onMouseLeave={() => setHoveredRow(null)}
                                        onClick={() => totalArgs.onFileClick(row.classification_id)}
                                    >
                                        {row.classification_filename}
                                    </TableCell>
                                    <TableCell>{row.upload_status}</TableCell>
                                    <TableCell>{row.classification_status}</TableCell>
                                    <TableCell>{row.classifications_created_at}</TableCell>
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                    <TableFooter sx={{
                        position: 'fixed',
                        bottom: 15,
                        backgroundColor: '#fff',
                        borderBottom: '2px solid #f1f1f1',
                        width: '83%'
                    }}>
                        <TableRow>
                            <TableCell colSpan={5}>
                                <Box sx={{display: 'flex', alignItems: 'center', marginBottom: 2, mt: 2}}>
                                    <Typography sx={{marginRight: 1, fontSize: '0.875rem', fontWeight: 'bold'}}>Per
                                        Page:</Typography>
                                    <FormControl variant="outlined" size="small"
                                                 sx={{
                                                     minWidth: 80,
                                                     marginRight: 2,
                                                     '& .MuiOutlinedInput-root': {
                                                         '& fieldset': {
                                                             borderColor: '#674f87',
                                                         },
                                                         '&:hover fieldset': {
                                                             borderColor: '#ef798a',
                                                         },
                                                         '&.Mui-focused fieldset': {
                                                             borderColor: '#ef798a',
                                                         },
                                                     },
                                                 }}
                                    >
                                        <Select
                                            labelId="limit-label"
                                            id="limit"
                                            value={limit}
                                            onChange={handleLimitChange}
                                            sx={{fontSize: '1rem',}}
                                        >
                                            <MenuItem value={15}>15</MenuItem>
                                            <MenuItem value={20}>20</MenuItem>
                                            <MenuItem value={50}>50</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <Pagination currentPage={currentPage} totalPages={totalPages}
                                                onPageChange={handlePageChange} limit={limit}/>
                                </Box>
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </div>
    );
};
const ExtractionDetails = (totalArgs) => {
    const [limit, setLimit] = useState(15);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [extractionDetails, setExtractionDetails] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [extractionFilter, setExtractionFilter] = useState('');
    const [labelFilter, setLabelFilter] = useState('');


    useEffect(() => {
        const apiCaller = async () => {
            setIsLoading(true);
            const params = createParams(limit, currentPage, extractionFilter, labelFilter);
            if (totalArgs.selectedClassificationId) {
                params.classification_id = totalArgs.selectedClassificationId;
            }
            const response = await api.get('data/extractions', {params});
            setIsLoading(false);

            if (response.status !== 200) {
                console.error('Files loading error', response.status);
                return false;
            }
            const responseData = response.data;
            setTotalPages(responseData.count);
            setExtractionDetails(responseData.results);
        };
        apiCaller();
    }, [limit, currentPage, totalArgs.selectedClassificationId, extractionFilter, labelFilter]);


    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleLimitChange = (event) => {
        setLimit(Number(event.target.value));
        setCurrentPage(1);
    };

    //For extraction label
    const handleEctractionLabelFilterChange = (event) => {
        setLabelFilter(event.target.value);
    };

    const filteredLabelDetails = extractionDetails.filter((row) => {
        if (!labelFilter) return true; // No filter applied, show all
        return row.extraction_label === labelFilter;
    });

    //For extraction status
    const handleEctractionFilterChange = (event) => {
        setExtractionFilter(event.target.value);
    };

    const filteredExtractionDetails = filteredLabelDetails.filter((row) => {
        if (!extractionFilter) return true; // No filter applied, show all
        return row.extraction_status === extractionFilter;
    });


    return (
        <div>
            <TableContainer component={Paper} sx={{height: '70vh'}}>
                <Table stickyHeader sx={{minWidth: 650}} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{fontWeight: 'bold'}}>Index</TableCell>
                            <TableCell sx={{fontWeight: 'bold'}}>Classification Type</TableCell>
                            <TableCell sx={{fontWeight: 'bold'}}>File Name</TableCell>
                            <TableCell sx={{fontWeight: 'bold'}}>
                                Extraction Label
                                <FormControl variant="outlined" size="small" sx={{justifyContent: 'center'}}>
                                    <Select
                                        value={labelFilter}
                                        onChange={handleEctractionLabelFilterChange}
                                        displayEmpty
                                        sx={{
                                            justifyContent: 'center',
                                            height: '25px',
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    borderColor: '#674f87',
                                                },
                                                '&:hover fieldset': {
                                                    borderColor: '#ef798a',
                                                },
                                                '&.Mui-focused fieldset': {
                                                    borderColor: '#ef798a',
                                                },
                                            },
                                        }}
                                    >
                                        <MenuItem value="">
                                            <FilterAltOffIcon sx={{fontSize: 17, paddingTop: '2px'}}/>
                                        </MenuItem>
                                        <MenuItem value="0">Legal</MenuItem>
                                        <MenuItem value="1">Invoice</MenuItem>
                                        <MenuItem value="2">Purchace Order</MenuItem>
                                        <MenuItem value="3">Unclassified</MenuItem>
                                    </Select>
                                </FormControl>
                            </TableCell>
                            <TableCell sx={{fontWeight: 'bold'}}>
                                Extraction Status
                                <FormControl variant="outlined" size="small" sx={{justifyContent: 'center'}}>
                                    <Select
                                        value={extractionFilter}
                                        onChange={handleEctractionFilterChange}
                                        displayEmpty
                                        sx={{
                                            justifyContent: 'center',
                                            height: '25px',
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    borderColor: '#674f87',
                                                },
                                                '&:hover fieldset': {
                                                    borderColor: '#ef798a',
                                                },
                                                '&.Mui-focused fieldset': {
                                                    borderColor: '#ef798a',
                                                },
                                            },
                                        }}
                                    >
                                        <MenuItem value="">
                                            <FilterAltOffIcon sx={{fontSize: 17, paddingTop: '2px'}}/>
                                        </MenuItem>
                                        <MenuItem value="extraction_completed">Completed</MenuItem>
                                        <MenuItem value="extraction_failed">Failed</MenuItem>
                                    </Select>
                                </FormControl>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isLoading ? (
                            <TableRow>
                                <TableCell colSpan={5} align="center">
                                    <Box sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        height: '100%'
                                    }}>
                                        <CircularProgress/>
                                        <Typography sx={{ml: 2}}>Loading data...</Typography>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        ) : (
                            filteredExtractionDetails.map((row, index) => (
                                <TableRow
                                    key={index}
                                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                >
                                    <TableCell component="th" scope="row">
                                        {/* {index + 1} */}
                                        {(currentPage - 1) * limit + index + 1}
                                    </TableCell>
                                    <TableCell>{row.classification_type}</TableCell>
                                    <TableCell>{row.extraction_filename}</TableCell>
                                    <TableCell>{row.extraction_label}</TableCell>
                                    <TableCell>{row.extraction_status}</TableCell>
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                    <TableFooter sx={{
                        position: 'fixed',
                        bottom: 15,
                        backgroundColor: '#fff',
                        borderBottom: '2px solid #f1f1f1',
                        width: '83%'
                    }}>
                        <TableRow>
                            <TableCell colSpan={5}>
                                <Box sx={{display: 'flex', alignItems: 'center', marginBottom: 2, mt: 2}}>
                                    <Typography sx={{marginRight: 1, fontSize: '0.875rem', fontWeight: 'bold'}}>Per
                                        Page:</Typography>
                                    <FormControl variant="outlined" size="small"
                                                 sx={{
                                                     minWidth: 80,
                                                     marginRight: 2,
                                                     '& .MuiOutlinedInput-root': {
                                                         '& fieldset': {
                                                             borderColor: '#674f87',
                                                         },
                                                         '&:hover fieldset': {
                                                             borderColor: '#ef798a',
                                                         },
                                                         '&.Mui-focused fieldset': {
                                                             borderColor: '#ef798a',
                                                         },
                                                     },
                                                 }}
                                    >
                                        <Select
                                            labelId="limit-label"
                                            id="limit"
                                            value={limit}
                                            onChange={handleLimitChange}
                                            sx={{fontSize: '1rem',}}
                                        >
                                            <MenuItem value={15}>15</MenuItem>
                                            <MenuItem value={20}>20</MenuItem>
                                            <MenuItem value={50}>50</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <Pagination currentPage={currentPage} totalPages={totalPages}
                                                onPageChange={handlePageChange} limit={limit}/>
                                </Box>
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </div>
    );
};

export default Status;
