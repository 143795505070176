import React, {useState} from 'react';
import {Drawer, Toolbar, Divider, Box, List, ListItem, ListItemIcon, ListItemText, Tooltip} from '@mui/material';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowCircleDown, faArrowCircleUp} from '@fortawesome/free-solid-svg-icons';
import {useNavigate} from 'react-router-dom';
import {mainNavbarItems, mainProfileItems} from './consts/navbarItems';
import {navbarStyles} from './styles';
import {useMediaQuery} from '@mui/material';
import eventEmitter from '../../pages/AR-Dashboard/EvenEmitter';
import {useAuth} from '../../contexts/AuthContext';

import {useNavbar} from './NavbarContext';

const Navbar = () => {
    const navigate = useNavigate();
    const [isCollapsed, setIsCollapsed] = useState(false);
    const isSmallerScreen = useMediaQuery('(max-width:600px)'); // Define your breakpoint
    const {role} = useAuth();
    const [selectedItem, setSelectedItem] = useState(null);


    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };
    // Automatically toggle collapse based on screen size
    React.useEffect(() => {
        if (isSmallerScreen) {
            setIsCollapsed(true);
        } else {
            setIsCollapsed(false);
        }
    }, [isSmallerScreen]);

    const handleDashboardClick = () => {
        eventEmitter.emit('redirectToDashboard');
        navigate('/documents'); // Navigate to Dashboard page
    };

    const handleItemClick = (route, id) => {
        setSelectedItem(id);
        if (route === 'documents') {
            handleDashboardClick();
        } else {
            navigate(route);
        }
    };

    const filteredNavbarItems = mainNavbarItems.filter(item => {
        if (role === 'admin') {
        return true;
        }
        return item.id === 1;
    });

    return (
        <Drawer
            sx={{...navbarStyles.drawer, width: isCollapsed ? 57 : 200}}
            variant="permanent"
            anchor="left"
        >
            <Toolbar/>
            <Divider/>
            <Box sx={{mt: 12}}>
                <List>
                    {filteredNavbarItems.map((item, index) => (
                        <ListItem
                            sx={navbarStyles.list}
                            button
                            key={item.id}
                            onClick={() => handleItemClick(item.route, item.id)}
                            selected={selectedItem === item.id}
                        >
                            {isCollapsed ? (
                                <Tooltip title={item.label}>
                                    <ListItemIcon
                                        sx={navbarStyles.icons}
                                    >
                                        {item.icon}
                                    </ListItemIcon>
                                </Tooltip>
                            ) : (
                                <>
                                    <ListItemIcon
                                        sx={navbarStyles.icons}
                                    >
                                        {item.icon}
                                    </ListItemIcon>
                                    <ListItemText
                                        // sx={navbarStyles.text}
                                        primary={item.label}
                                    />
                                </>
                            )}
                        </ListItem>
                    ))}
                </List>
            </Box>
            <Box sx={{position: 'absolute', bottom: 0, mt: 12}}>
                <List>
                    {mainProfileItems.map((item, index) => (
                        <ListItem
                            sx={navbarStyles.list}
                            button
                            key={item.id}
                            onClick={() => navigate(item.route)}
                        >
                            {isCollapsed ? (
                                <Tooltip title={item.label}>
                                    <ListItemIcon
                                        sx={navbarStyles.icons}
                                    >
                                        {item.icon}
                                    </ListItemIcon>
                                </Tooltip>
                            ) : (
                                <>
                                    <ListItemIcon
                                        sx={navbarStyles.icons}
                                    >
                                        {item.icon}
                                    </ListItemIcon>
                                    <ListItemText
                                        // sx={navbarStyles.text}
                                        primary={item.label}
                                    />
                                </>
                            )}
                        </ListItem>
                    ))}
                </List>
                {!isSmallerScreen && (
              <button onClick={toggleCollapse} hidden={true}></button>
                )}
            </Box>
        </Drawer>
    );
};

export default Navbar;
