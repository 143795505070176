import React, {useState, useEffect} from 'react';
import {Button, Box, TextField, Tooltip, Typography} from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import {setCurrentPage} from "react-doc-viewer/build/plugins/pdf/state/actions";

const Pagination = ({currentPage, totalPages, onPageChange, limit, next, previous}) => {
    const [inputPage, setInputPage] = useState(currentPage);
    const [showError, setShowError] = useState(false);

    useEffect(() => {
        setInputPage(currentPage);
    }, [currentPage]);


    const handlePageChange = (pageNumber) => {
        if (pageNumber >= 1 && pageNumber <= Math.ceil(totalPages / limit)) {
            onPageChange(pageNumber);
            setShowError(false); // Hide error if page number is valid
        } else {
            setShowError(true); // Show error if page number is invalid
        }
        console.warn(currentPage, Math.ceil(totalPages / limit), limit);
    };

    const handleInputChange = (event) => {
        setInputPage(event.target.value);
    };

    const handleInputSubmit = () => {
        const pageNumber = parseInt(inputPage, 10);
        if (!isNaN(pageNumber)) {
            handlePageChange(pageNumber);
            setInputPage(''); // Clear input field after submitting
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleInputSubmit();
        }
    };

    const getPaginationItems = () => {
        const items = [];
        const range = 2; // Number of page buttons to show before and after the current page

        for (let i = 1; i <= Math.ceil(totalPages / limit); i++) {
            if (i <= range || i > totalPages - range || (i >= currentPage - range && i <= currentPage + range)) {
                items.push(i);
            } else if (i === range + 1 || i === totalPages - range) {
                items.push('...');
            }
        }
        return items;
    };

    const paginationItems = getPaginationItems();

    return (
        <Box sx={{display: 'flex', alignItems: 'center'}}>

            <Typography sx={{fontSize: '0.875rem', fontWeight: 'bold'}}>Page No :</Typography>
            <Tooltip
                title="Page number is out of range"
                open={showError}
                arrow
                disableHoverListener
                placement="top"
            >

                <TextField
                    type="number"
                    value={inputPage}
                    onChange={handleInputChange}
                    onBlur={handleInputSubmit}
                    onKeyPress={handleKeyPress}
                    size="small"
                    inputProps={{min: 1, max: totalPages}}
                    error={showError}
                    placeholder={currentPage}
                    sx={{
                        ml: 1,
                        width: '80px', // increased width to accommodate icon
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: '#674f87',
                            },
                            '&:hover fieldset': {
                                borderColor: '#ef798a',
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: '#ef798a',
                            },
                        },
                    }}
                />
            </Tooltip>

            <Button
                variant="contained"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                size="small"
                sx={{background: 'linear-gradient(to right, #ef798a, #674f87)', marginLeft: 2}}
            >
                <KeyboardArrowLeftIcon/>
            </Button>

            {paginationItems.map((item, index) => (
                item === '...' ? (
                    <Button key={index} variant="text" size="small" disabled
                            sx={{
                                mx: 0.5,
                                fontWeight: 'bold',
                                backgroundImage: 'linear-gradient(to right, #ef798a, #674f87)',
                                WebkitBackgroundClip: 'text',
                                WebkitTextFillColor: 'transparent',
                                ...(item === currentPage ? {fontWeight: 'bold'} : {}),
                            }}
                    >
                        {item}
                    </Button>
                ) : (
                    <Button
                        key={index}
                        variant={item === currentPage ? "contained" : "text"}
                        onClick={() => handlePageChange(item)}
                        size="small"
                        sx={{
                            mx: 0.5,
                            fontWeight: 'bold',
                            backgroundImage: 'linear-gradient(to right, #ef798a, #674f87)',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent',
                            ...(item === currentPage ? {fontWeight: 'bold'} : {}),
                        }}
                    >
                        {item}
                    </Button>
                )
            ))}

            <Button
                variant="contained"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                size="small"
                sx={{background: 'linear-gradient(to right, #ef798a, #674f87)',}}
            >
                <KeyboardArrowRightIcon/>
            </Button>
            <Typography sx={{fontSize: '0.875rem', fontWeight: 'bold', ml: 4}}>
                Page {currentPage} of {Math.ceil(totalPages / limit)}
            </Typography>
        </Box>
    );
};

export default Pagination;
