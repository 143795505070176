import React, {useState, useEffect, useMemo, useRef} from 'react';
import Grid from '@mui/material/Grid';
import GridWrapper from '../../components/common/GridWrapper/GridWrapper';
import {useNavbar} from '../../components/Navbar/NavbarContext';
// import EF from './ef2';
import api from "../../API";
import WopiFrame from '../../components/wopi/WopiFrame';
import {useAuth} from "../../contexts/AuthContext";
import DocViewer, {DocViewerRenderers} from "@cyntler/react-doc-viewer";
import "@cyntler/react-doc-viewer/dist/index.css";
import "./customDocViewer.css";
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';
import GradButton from '../../components/Grad_Buttons/grad-icon-buttton';
import GradientButtonIcon from '../../components/Grad_Buttons/GradientButtonIcon'
import DownloadIcon from '@mui/icons-material/Download';
import SaveIcon from '@mui/icons-material/Save';
import SearchIcon from '@mui/icons-material/Search';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import HubIcon from '@mui/icons-material/Hub';
import Stack from '@mui/material/Stack';
import {CircularProgress, Tooltip, tooltipClasses, DialogContentText} from '@mui/material';
import Typography from '@mui/material/Typography';
import HeadingTagsPopover from './EF2_popover_tag';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import SummarizeIcon from '@mui/icons-material/Summarize';
import EditIcon from '@mui/icons-material/Edit';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import CustomSnackbar from '../../components/SnackBar/Snackbar';
import {Chip} from '@mui/material';

function DisplayDoc({doc, doc_id, handleDocBack}) {
    const {user, role} = useAuth();
    const [quietMode, setQuietMode] = useState(null);
    const [error, setError] = useState(null);
    const [iframeUrl, setIframeUrl] = useState('');
    const [accessToken, setAccessToken] = useState('');
    const [selectedTags, setSelectedTags] = useState([]);
    const [showPassword, setShowPassword] = React.useState(false);
    const [tags, setTags] = useState([]);
    const [tagChange, setTagChange] = useState(false);
    const [newName, setNewName] = useState('');
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const [snackbarOpen, setSnackBarOpen] = React.useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [tempItem, setTempItem] = useState(doc);
    const [selectedVersionId, setSelectedVersionId] = useState(doc.document_version_id);
    const [versions_list, setVersionList] = useState([]);
    const [lastEditLocation, setLastEditLocation] = useState(null);
    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState([]);
    const [isChatVisible, setIsChatVisible] = useState(true);
    const chatContentRef = useRef(null);
    const {setCollapsed} = useNavbar();
    const [open, setOpen] = useState(false);
    const isAdmin = ['admin'].some(userRole => role.includes(userRole));
    const isUser = ['user'].some(userRole => role.includes(userRole));
    setCollapsed(true);
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });
    const [editDoc, setEditDoc] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedDoc, setSelectedDoc] = useState('');
    const [loading, setLoading] = useState(false);
    const [addedTags, setAddedTags] = useState([])
    const [closeOpen, setCloseOpen] = React.useState(false);
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

    const memoizedDocViewer = useMemo(() => {
        return (
            <>
                {selectedDoc && !loading && (
                    <DocViewer
                        documents={[{
                            uri: selectedDoc.blob ? window.URL.createObjectURL(selectedDoc.blob) : ''
                        }]}
                        pluginRenderers={DocViewerRenderers}
                        config={{
                            header: {
                                disableFileName: true,
                            },
                        }}
                    />

                )}
            </>

        );
    }, [loading, selectedDoc]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const onResize = () => {
        setWindowSize({width: window.innerWidth, height: window.innerHeight});
    };
    const handleTextChange = (e) => {
        const {name, value} = e.target;
        setTempItem((prevItem) => ({
            ...prevItem,
            [name]: value,
        }));
        setHasUnsavedChanges(true);
    };

    const handleCloseOpen = () => {
        console.log('Opening')
        setCloseOpen(true);
    };
    const handleClosePopup = () => {
        console.log('Closing')
        setCloseOpen(false);
    };

    const handleCloseButton = () => {
        if (hasUnsavedChanges) {
            handleCloseOpen();
        } else {
            handleDocBack();
        }
    };
    const handleDownload = async () => {
        try {
            const response = await api.get(`data/documents?document_id=${doc_id}&file=true&download=true`, {
                responseType: 'arraybuffer'
            });
            if (response.status !== 200) {
                console.error('File fetch error: Status code', response.status);
                return false;
            }
            // console.log('Response headers:', response.headers);
            // console.log('Response data length:', response.data.byteLength);
            // console.log('jsonResponse', response);
            const fileType = response.headers['content-type'] || 'application/octet-stream';
            const docBlob = new Blob([response.data], {type: fileType});
            const docDataUrl = URL.createObjectURL(docBlob);
            const link = document.createElement('a');
            link.href = docDataUrl;
            link.download = doc.document_super_name + doc.document_version_location.substring(doc.document_version_location.lastIndexOf('.'));
            document.body.appendChild(link);
            link.click();
            URL.revokeObjectURL(docDataUrl);
            document.body.removeChild(link);
            showSnackbar('Document Downloaded successfully')
        } catch (err) {
            console.error(err);
        }
    };
    const handleVersionChange = (newVersionId) => {
        setSelectedVersionId(newVersionId);
    };

    const updateTagsInItem = (item, selectedTags) => {
        return {
            ...item,
            tags: selectedTags
        };
    };

    const fetchVersion = async (url) => {
        try {
            const response = await api.get(url);
            if (response.status !== 200) {
                console.error('Files loading error', response.status);
                return false;
            }
            const responseData = await response.data;
            const versions_data = responseData.results
            setVersionList(versions_data);
            return true;
        } catch (error) {
            console.error('Error fetching Versions:', error);
        }
        return false;
    };


    const update_backend = async (updatedItem) => {
        try {
            const response = await api.put('data/documents', updatedItem);
            if (response.status !== 200) {
                console.error('File fetch error: Status code', response.status);
                return false;
            }
            console.log('jsonResponse', response.data);
        } catch (err) {
            console.error('Error updating document:', err);
            return false;
        }
        return true;
    };

    const handleDocumentSave = async () => {
        try {
            const updatedItem = updateTagsInItem(tempItem, selectedTags);
            const result = await update_backend(updatedItem); // Properly await the async call
            if (result) {
                console.log('Document successfully updated');
                showSnackbar('Document Saved successfully')
                setHasUnsavedChanges(false);
            } else {
                console.error('Failed to update the document');
            }
        } catch (err) {
            console.error('Error handling document save:', err);
        }
    };


    const handleToggleChat = () => {
        setIsChatVisible(!isChatVisible);
    };
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    };


    const handleSearch = () => {
        if (message.trim() === '') return;
        const newMessages = [...messages, {from: 'user', text: message}];
        setMessages(newMessages);
        const data = {
            inputMessage: message,
            FileId: doc.document_id
        };
        api.post('ai/get_replay', data)
            .then(response => {
                const newMessages = [
                    ...messages,
                    {from: 'user', text: message},
                    {from: 'backend', text: response.data.response}
                ];
                setMessages(newMessages);
                setMessage('');
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    };

    const Summarize = () => {
        const newMessages = [...messages, {from: 'user', text: 'Summarizing...'}];
        setMessages(newMessages);

        setTimeout(() => {
            console.log('---!!', doc.document_super_name);
            const data = {
                inputMessage: 'Summarize this document',
                FileId: doc.document_id
            };

            api.post('ai/get_summary', data)
                .then(response => {
                    const responseData = response.data;
                    console.log('--responseData-!!', responseData);
                    const newMessages = [
                        ...messages,
                        {from: 'user', text: "Summarizing..."},
                        {from: 'backend', text: responseData.response}
                    ];
                    setMessages(newMessages);
                    setMessage('');
                })
                .catch(error => {
                    console.error('There was an error!', error);
                });
        }, 0);
    };

    const handleTagsChange = (tags) => {
        setSelectedTags(tags.selectedTags);
        setHasUnsavedChanges(true);
    };
    const handleDeleteTag = (tagToDelete) => {
        const updatedTags = selectedTags.filter((tag) => tag.tag_id !== tagToDelete.tag_id);
        setSelectedTags(updatedTags);
        setHasUnsavedChanges(true);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setNewName('');
        setOpen(false);
    };

    const handleSave = async () => {
        const newRow = {tag_name: newName, document_id: doc.document_id};
        const response = await api.post('data/tags', newRow);
        if (response.status !== 200) {
            showSnackbar('Tag save unsuccessful');
            return false;
        }
        const getResponse = await api.get(`data/tags?document_ids=${doc.document_id}`);
        if (getResponse.status === 200) {
            setAddedTags(getResponse.data.results)
        }
        showSnackbar('Tag save successful');
        setNewName('');
        setTagChange(true);
        handleClose();
    };

    window.selectedTagList = [];

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackBarOpen(false);
    };

    const showSnackbar = (message) => {
        setSnackbarMessage(message);
        setSnackBarOpen(true);
    };


    const fetchDocUrl = async (url) => {
        setLoading(true);
        setError(null);
        try {
            const response = await api.get(url, {
                responseType: 'arraybuffer',
            });

            if (response.status !== 200) {
                console.error('File fetch error: Status code', response.status);
                setError(`File fetch error: Status code ${response.status}`);
                return false;
            }

            console.log('Response headers:', response.headers);
            const fileType = response.headers['content-type'] || 'application/octet-stream';
            const docBlob = new Blob([response.data], {type: fileType});
            const docObject = {
                blob: docBlob
            };
            setSelectedDoc(docObject);
            return true;
        } catch (err) {
            showSnackbar('Failed to fetch document');
        } finally {
            setLoading(false);
        }
        return false;
    };


    const handleEdit = () => {
        setEditDoc(true);
    };


    const handleEditClose = () => {
        setEditDoc(false);
    };

    useEffect(() => {
        if (selectedVersionId) {
            const docVersionQuery = selectedVersionId ? `&document_version_id=${selectedVersionId}` : '';
            const url = `data/documents?document_id=${doc_id}${docVersionQuery}&file=true`;
            fetchDocUrl(url);
        }
    }, [doc, selectedVersionId]);

    useEffect(() => {
        const wopiUrl = process.env.REACT_APP_WOPI_SERVER;
        const backendUrl = process.env.REACT_APP_BACKEND_API_URL;
        if (['maintainer'].some(doc => ['maintainer', 'admin'].includes(doc))) {
            setAccessToken(process.env.REACT_APP_WOPI_SERVER_EDIT_ACCESS_TOKEN);
        } else {
            setAccessToken(process.env.REACT_APP_WOPI_SERVER_ACCESS_TOKEN);
        }
        setAccessToken(process.env.REACT_APP_WOPI_SERVER_EDIT_ACCESS_TOKEN);
        if (wopiUrl && backendUrl && doc.document_id && selectedVersionId) {
            setIframeUrl(`${wopiUrl}${backendUrl}/api/wopi/files/${doc.document_id}_${selectedVersionId}`);
        }
    }, [editDoc, doc, selectedVersionId]);

    useEffect(() => {
        window.addEventListener('resize', onResize);
        return () => window.removeEventListener('resize', onResize);
    }, []);


    useEffect(() => {
        const combinedTags = [...doc.tags, ...addedTags];
        const tagMap = new Map();
        combinedTags.forEach(tag => {
            tagMap.set(tag.tag_id, tag);
        });
        const uniqueTags = Array.from(tagMap.values());
        setSelectedTags(uniqueTags);
    }, [doc, addedTags]);


    useEffect(() => {
        const urlWithParams = `data/documents?document_id=${doc_id}`;
        const url = encodeURI(urlWithParams);
        fetchVersion(url);
    }, [doc_id]);

    /*    useEffect(() => {
            if (versions_list.length > 0) {
                handleVersionChange(versions_list[0].document_version_id);
            }
        }, [versions_list]);*/

    useEffect(() => {
        if (chatContentRef.current) {
            chatContentRef.current.scrollTop = chatContentRef.current.scrollHeight;
        }
    }, [messages]);

    useEffect(() => {
        if (isChatVisible) {
            Summarize();
        }
    }, []);


    useEffect(() => {
        const fetchTag = async () => {
            try {
                const response = await api.get(`data/tags`);
                if (response.status !== 200) {
                    console.error('File fetch error: Status code', response.status);
                    return false;
                }
                const responseData = await response.data;
                setTags(responseData.results)
            } catch (err) {
                console.error(err);
            }
        };
        fetchTag();
    }, [doc, tagChange]);

    const handleNewTagChange = (event) => {
        setNewName(event.target.value);
    }

    if (editDoc) {
        return (
            <WopiFrame handleEditClose={handleEditClose} iframeSrc={iframeUrl} accessToken={accessToken}
                       editDoc={editDoc} user={user}/>
        );
    } else {
        return (
            <>
                <GridWrapper>
                    <Grid container spacing={2} sx={{paddingTop: 2}}>
                        <Grid item xs={5} sx={{background: '#ffffff'}}>
                            <Box sx={{
                                mt: '5px',
                                height: '850px',
                                background: `linear-gradient(to bottom, #ffffff, #eae8ed)`,
                                border: '2px solid #d8d8d8',
                                borderRadius: '10px'
                            }}>
                                <div className='DialogueBox' style={{marginTop: '30px', marginLeft: '88%'}}>
                                    {/* <React.Fragment> */}
                                    {/* <Button variant="contained" onClick={handleClickOpen} startIcon={<AddCircleOutlineIcon />} size='small' color='secondary' >
                        Create
                    </Button> */}
                                    <Dialog
                                        open={open}
                                        onClose={handleClose}
                                        PaperProps={{
                                            component: 'form',
                                        }}
                                    >
                                        <DialogTitle>Create New Tag</DialogTitle>
                                        <DialogContent>
                                            <TextField
                                                autoFocus
                                                required
                                                margin="dense"
                                                id="name"
                                                label="Name"
                                                size="small"
                                                color='secondary'
                                                type="text"
                                                fullWidth
                                                variant="standard"
                                                value={newName}
                                                onChange={handleNewTagChange}
                                                sx={{
                                                    '& .MuiInput-underline:hover:before': {
                                                        borderBottomColor: '#9E3C5D',
                                                    },
                                                    '& .MuiInput-underline:after': {
                                                        borderBottomColor: '#9E3C5D',
                                                    },
                                                    '& .MuiFormLabel-root.Mui-focused': {
                                                        color: '#9E3C5D',
                                                    },
                                                }}
                                            />

                                        </DialogContent>
                                        <DialogActions>
                                            <Button variant='outlined' size="small" color='secondary'
                                                    onClick={handleClose}
                                                    sx={{
                                                        color: '#9E3C5D',
                                                        backgroundColor: '#f1f1f1',
                                                        border: '1px solid #9E3C5D '
                                                    }}>
                                                Cancel
                                            </Button>
                                            <Button variant='outlined' size="small" color='secondary'
                                                    onClick={handleSave}
                                                    disabled={!newName.trim()}
                                                    sx={{
                                                        color: '#9E3C5D',
                                                        backgroundColor: '#f1f1f1',
                                                        border: '1px solid #9E3C5D '
                                                    }}>
                                                Save
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                    {/* </React.Fragment> */}
                                </div>
                                <div>
                                    <CustomSnackbar
                                        open={snackbarOpen}
                                        message={snackbarMessage}
                                        onClose={handleCloseSnackbar}
                                    />
                                </div>

                                <div>
                                    {isAdmin && (
                                        <Stack
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="baseline"
                                            spacing={1}
                                            sx={{marginInline: 2}}
                                        >
                                            <Tooltip title="Downlaod">
                                                <span><GradientButtonIcon handleClick={handleDownload}
                                                                          header_icon={DownloadIcon}/></span>
                                            </Tooltip>
                                            <Tooltip title="Save">
                                                <span><GradientButtonIcon handleClick={handleDocumentSave}
                                                                          header_icon={SaveIcon}/></span>
                                            </Tooltip>

                                            <GradButton header={'AI'} handleClick={handleToggleChat}
                                                        header_icon={AutoAwesomeIcon}/>
                                            {(doc.document_version_location.endsWith('.docx') || doc.document_version_location.endsWith('.doc')) && (
                                                <GradButton header={'Edit'} handleClick={handleEdit}
                                                            header_icon={EditIcon}/>
                                            )}
                                            {/*<SelectVersion filteredVersions={versions_list}
                                                           handleVersionChange={handleVersionChange}
                                                           selectedVersionId={selectedVersionId}/>*/}
                                            <FormControl
                                                sx={{
                                                    m: 1,
                                                    minWidth: 120,
                                                    '& .MuiOutlinedInput-root': {
                                                        height: '32px',
                                                        paddingRight: '10px',
                                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                                            borderColor: '#ef798a',
                                                        },
                                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline, &.MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                            borderColor: '#ef798a',
                                                        },
                                                    },
                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: selectedVersionId ? '#ef798a' : '#ccc',
                                                    },
                                                    '& .MuiOutlinedInput-input': {
                                                        padding: '10px',
                                                        lineHeight: '21px',
                                                    }
                                                }}
                                            >
                                                <InputLabel id="demo-select-small-label" color='secondary'
                                                            sx={{
                                                                '&.Mui-focused': {
                                                                    color: '#ef798a',
                                                                },
                                                                transform: selectedVersionId ? 'translate(14px, -7px) scale(0.75)' : 'translate(14px, 9px) scale(1)',
                                                                transformOrigin: 'top left',
                                                                transition: 'transform 0.2s ease-out',
                                                                backgroundColor: selectedVersionId ? '#fff' : 'transparent', // Adding background to avoid overlap with the border
                                                                padding: selectedVersionId ? '0 2px' : '0', // Add padding to label when focused or has value
                                                            }}
                                                >
                                                    Version
                                                </InputLabel>
                                                <Select
                                                    labelId="demo-select-small-label"
                                                    id="demo-select-small"
                                                    value={selectedVersionId}
                                                    label="Version"
                                                    onChange={handleVersionChange}
                                                    color='secondary'
                                                    sx={{
                                                        '& .MuiSelect-select': {
                                                            paddingTop: '8px',
                                                            paddingBottom: '8px',
                                                            fontSize: '14px',
                                                        },
                                                    }}
                                                    className={selectedVersionId ? 'Mui-focused' : ''}
                                                >
                                                    {versions_list.map((version, index) => (
                                                        <MenuItem key={version.document_version_id}
                                                                  value={version.document_version_id}>
                                                            V{versions_list.length - index}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            <Box sx={{flexGrow: 1,}}/>
                                            <Tooltip title="Close">
                                                <span><GradButton header={'Close'} handleClick={handleCloseButton}
                                                                  header_icon={HighlightOffIcon}/></span>
                                            </Tooltip>

                                        </Stack>
                                    )}
                                    {isUser && (
                                        <Stack
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="baseline"
                                            spacing={1}
                                            sx={{marginInline: 2}}
                                        >
                                            <Tooltip title="Save">
                                                <span><GradientButtonIcon handleClick={handleDocumentSave}
                                                                          header_icon={SaveIcon}/></span>
                                            </Tooltip>
                                            <GradButton header={'AI'} handleClick={handleToggleChat}
                                                        header_icon={HubIcon}/>
                                            <Box sx={{flexGrow: 1,}}/>
                                            <GradientButtonIcon handleClick={handleCloseButton}
                                                                header_icon={HighlightOffIcon}/>
                                        </Stack>
                                    )}
                                    <React.Fragment>
                                        <Dialog
                                            open={closeOpen}
                                            onClose={handleClosePopup}
                                            aria-describedby="alert-dialog-description"
                                        >
                                            <DialogContent>
                                                <DialogContentText
                                                    id="alert-dialog-description">
                                                    Your recent changes have not been saved.Are you sure you want to
                                                    close this file?
                                                </DialogContentText>
                                            </DialogContent>
                                            <DialogActions>
                                                <Button size='small' variant='outlined' color='secondary'
                                                        onClick={handleClosePopup}>No</Button>
                                                <Button size='small' variant='outlined' color='secondary'
                                                        onClick={handleDocBack}
                                                        autoFocus>Yes</Button>
                                            </DialogActions>
                                        </Dialog>
                                    </React.Fragment>
                                </div>
                                <div style={{display: 'flex', justifyContent: 'center'}}>
                                    {lastEditLocation && (
                                        <Alert variant="outlined" severity="info"
                                               sx={{
                                                   marginTop: 2,
                                                   bgcolor: '#f1f1f1',
                                                   border: 'none',
                                                   boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.5)',
                                                   '& .MuiAlert-icon': {
                                                       color: '#9E3C5D',
                                                       alignSelf: 'center',
                                                   },
                                                   display: 'flex',
                                                   alignItems: 'center',
                                                   height: 40,
                                                   width: 'fit-content',
                                               }}
                                        >
                                            Your recent changes have not been saved. Please ensure you save your
                                            changes.
                                        </Alert>
                                    )}
                                </div>

                                <div style={{marginTop: '60px'}}>
                                    <Typography variant="h1" component="h2" color='secondary' sx={{marginLeft: 2}}>
                                        {doc.document_super_name}
                                    </Typography>
                                </div>

                                <div>
                                    <FormControl fullWidth sx={{m: 2, width: '90%'}}>
                                        <Grid container spacing={2} alignItems="center">
                                            <Grid item xs={4}>
                                                <label>Document</label>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TextField
                                                    required
                                                    id="doc-no"
                                                    name="document_sub_name"
                                                    size='small'
                                                    color='secondary'
                                                    label="Document Name"
                                                    defaultValue={tempItem.document_sub_name}
                                                    InputProps={{
                                                        readOnly: isUser,
                                                    }}
                                                    onChange={handleTextChange}
                                                />
                                            </Grid>
                                        </Grid>
                                    </FormControl>
                                </div>
                                <div>
                                    <FormControl fullWidth sx={{m: 2, width: '90%'}}>
                                        <Grid container spacing={2} alignItems="center">
                                            <Grid item xs={4}>
                                                <label>Document Type</label>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TextField
                                                    id="outlined-required"
                                                    name="document_type"
                                                    size='small' color='secondary'
                                                    label='Document Type'
                                                    defaultValue={tempItem.document_type}
                                                    InputProps={{
                                                        readOnly: isUser,
                                                    }}
                                                    onChange={handleTextChange}
                                                />
                                            </Grid>
                                        </Grid>
                                    </FormControl>
                                </div>
                                <div>
                                    <FormControl fullWidth sx={{m: 2, width: '90%'}}>
                                        <Grid container spacing={2} alignItems="center">
                                            <Grid item xs={4}>
                                                <label>Tags</label>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <HeadingTagsPopover
                                                    tags={tags}
                                                    default_tags={doc.tags}
                                                    header={'update tags'}
                                                    header_icon={LocalOfferIcon}
                                                    onChangeFunction={handleTagsChange}
                                                    selectedTags={selectedTags}
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickOpen}
                                                    edge="end"
                                                >
                                                    <AddIcon/>
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    </FormControl>
                                    <Stack direction="row" spacing={1} sx={{marginInline: 2}}>
                                        {selectedTags.map((tag) => (
                                            <Chip key={tag.tag_id} label={tag.tag_name} variant="outlined"
                                                  color='secondary'
                                                  onDelete={() => handleDeleteTag(tag)}/>
                                        ))}
                                    </Stack>
                                </div>
                                <div>
                                    {isChatVisible && (
                                        <div className='chat-window'
                                             style={{
                                                 border: "2px solid #fff",
                                                 height: "380px",
                                                 width: "90%",
                                                 margin: "auto",
                                                 borderRadius: "10px",
                                                 boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                                                 display: 'flex',
                                                 flexDirection: 'column',
                                                 justifyContent: 'space-between',
                                                 marginTop: '50px'
                                             }}>
                                            <div className='chat-content' ref={chatContentRef}
                                                 style={{flex: 1, padding: '10px', overflowY: 'auto'}}>
                                                {messages.map((msg, index) => (
                                                    <div
                                                        key={index}
                                                        style={{
                                                            textAlign: msg.from === 'user' ? 'right' : 'left',
                                                            margin: '10px 0'
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                display: 'inline-block',
                                                                padding: '10px',
                                                                borderRadius: '10px',
                                                                backgroundColor: msg.from === 'user' ? '#525659' : '#d8d8d8',
                                                                color: msg.from === 'user' ? '#fff' : '#000'
                                                            }}
                                                        >
                                                            {msg.text}
                                                        </span>
                                                    </div>
                                                ))}
                                            </div>
                                            {/* <div className='fixedTags'
                                                 style={{justifyContent: "center", display: "flex", width: "100%"}}>
                                                <button
                                                    style={{
                                                        textAlign: 'center',
                                                        display: "block",
                                                        textDecoration: "none",
                                                        border: "1px solid #674F87",
                                                        fontSize: "13px",
                                                        padding: "3px 5px",
                                                        borderRadius: "5px",
                                                        color: "#9D3C5D"
                                                    }}
                                                    onClick={Summarize}
                                                    title='Summarize the select document'>Summarize Now{<SummarizeIcon
                                                    style={{height: "15px", width: "20px", paddingTop: "3px"}}/>}
                                                </button>
                                            </div> */}
                                            <div className='chat-input'
                                                 style={{display: 'flex', borderTop: '1px solid #ccc', padding: '4px'}}>
                                                <input
                                                    type='text'
                                                    value={message}
                                                    onChange={(e) => setMessage(e.target.value)}
                                                    onKeyPress={handleKeyPress}
                                                    style={{
                                                        flex: 1,
                                                        padding: '10px',
                                                        borderRadius: '5px',
                                                        border: '1px solid #ccc'
                                                    }}
                                                    placeholder='Chat with the document now...'
                                                />
                                                <button
                                                    onClick={handleSearch}
                                                    style={{
                                                        marginLeft: '10px',
                                                        borderRadius: '5px',
                                                        border: '1px solid #674F87',
                                                        color: '#9D3C5D',
                                                        cursor: 'pointer',
                                                        alignItems: "center",
                                                        backgroundColor: 'transparent'
                                                    }}
                                                >
                                                    <SearchIcon
                                                        style={{width: "22px", height: "22px", color: '#674F87'}}/>
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </Box>
                        </Grid>
                        <Grid item xs={6.6} sx={{background: '#ffffff'}}>
                            <Box
                                sx={{
                                    mt: '5px',
                                    height: '850px',
                                    background: `linear-gradient(to bottom, #ffffff, #eae8ed)`,
                                    border: '2px solid #d8d8d8',
                                    borderRadius: '10px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    overflow: 'auto'
                                }}
                            >
                                {memoizedDocViewer}
                            </Box>
                        </Grid>
                    </Grid>
                </GridWrapper>
            </>
        );
    }
};

export default DisplayDoc;
