import * as React from 'react';
import {AppBar, Toolbar, Typography, Button, Tooltip, CircularProgress} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {topmenuStyles} from './styles';
import Ai_logo from '../images/meta_docs_grey.svg';
import {useAuth} from "../../contexts/AuthContext";
import Avatar from '@mui/material/Avatar';
import PersonIcon from '@mui/icons-material/Person';
import Popover from '@mui/material/Popover';
import {useState} from "react";

export default function TopMenu() {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [loading, setLoading] = useState(false); // Added loading state

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;


    const {user, role, logout} = useAuth();
    const navigate = useNavigate();
    const handleLogout = async (e) => {
        setLoading(true);
        await logout();
        setLoading(false);
        navigate('/login');
    };
    return (
        <AppBar position="fixed" sx={topmenuStyles.top_menu}>
            <Toolbar>
                <div onClick={() => navigate('/documents')} style={{cursor: 'pointer'}}>
                    {/* <Typography noWrap component="img" src={Ai_logo} sx={{marginRight: 'auto', width: '10%'}}/> */}
                    <img src={Ai_logo} style={{width: '50%', height: '50%'}}
                         alt="A beautiful sunrise over the mountains"></img>
                </div>
                <div style={{flexGrow: 1}}/>
                <div>
                    <Tooltip title={user ? user.given_name : 'Guest'}>
                        <Avatar sx={{
                            border: '2px solid #f1f1f1',
                            cursor: 'pointer',
                            bgcolor: '#9D3C5D',
                            color: '#f1f1f1',
                            '&:hover': {bgcolor: '#432F60'}
                        }} onClick={handleClick}>
                            <PersonIcon/>
                        </Avatar>
                    </Tooltip>
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        <div>
                            {user ? (
                                <div style={topmenuStyles.userInfo}>
                                    <Typography variant="body1" sx={{color: '#757575'}}>
                                        {user.given_name}
                                    </Typography>
                                    <Typography variant="body2" sx={{color: '#757575'}}>
                                        role: {role}
                                    </Typography>
                                </div>
                            ) : (
                                <Typography variant="body1" sx={{marginRight: '16px', padding: '16px'}}>
                                    Guest
                                </Typography>
                            )}
                        </div>
                        <Button variant='contained' color='secondary' size='small' onClick={handleLogout}
                                sx={{
                                    marginTop: '8px',
                                    width: '100%',
                                    borderTop: '1px solid #e0e0e0',
                                    paddingTop: '8px',
                                    overflow: 'hidden',
                                    position: 'relative'
                                }} disabled={loading}
                                endIcon={loading ? (<CircularProgress
                                    size={24}
                                    color="secondary"
                                    sx={{
                                        position: 'absolute',
                                        top: '15%',
                                        left: '40%',
                                        transform: 'translate(-50%, -50%)'
                                    }}
                                />) : null}
                        >
                            {loading ? '...' : 'Logout'}
                        </Button>
                    </Popover>
                </div>
            </Toolbar>
        </AppBar>
    );
}
