import React, { useState } from 'react';
import BarChart from "../../components/charts/BarChart";
import ProcessingTimeChart from '../../components/charts/ProcessingTimeChart';
import AccuracyChart from '../../components/charts/accuracyChart';
import { useNavbar } from '../../components/Navbar/NavbarContext';
import ExploreButton from '../../components/exploreMoreButtons';
import ProcessCount from '../../components/charts/CountChart/ProcessingCount';
const AccountInfo = () => {
    const { setCollapsed } = useNavbar();
    setCollapsed(false);
    return (
      <div >
        <h2 style={{color: '#674f87', fontFamily: 'sans-serif', }}>
          Welcome to AiChemist
        </h2>
        <div style={{ display: 'flex', gap: '40px',padding:'10px' }}>
          <AccuracyChart value={90}></AccuracyChart>
          <ProcessingTimeChart value={5000000} totalValue={9000000}></ProcessingTimeChart>
          <BarChart ></BarChart>
          <ProcessCount value={5565189}></ProcessCount>


        </div>
        <div style={{marginTop: '300px', marginLeft: '10px'}}>
          <ExploreButton />
        </div>
      </div>

    )
}

export default AccountInfo

