import React, {useState, useEffect} from 'react';
import {useAuth} from '../../contexts/AuthContext';
import {useNavigate} from 'react-router-dom';
import {TextField, Button, Typography, IconButton, InputAdornment, CircularProgress} from '@mui/material';
import {Visibility, VisibilityOff} from '@mui/icons-material';
import './login.css';
import LogoImage from '../images/meta_grey.png';
import LoginImage from '../images/metadocs_text.svg';
import CustomSnackbar from "../../components/SnackBar/Snackbar";

const Login = ({onLogin}) => {
    const {login, error} = useAuth();
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [formData, setFormData] = useState({username: '', password: '', realm: 'internal'});
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false); // Added loading state
    const [snackbarOpen, setSnackBarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackBarOpen(false);
    };
    const showSnackbar = (message) => {
        setSnackbarMessage(message);
        setSnackBarOpen(true);
    };
    useEffect(() => {
        const logoutMessage = localStorage.getItem('logout_message');
        if (logoutMessage) {
            showSnackbar(logoutMessage);
            localStorage.removeItem('logout_message'); // Clear message after displaying
        }
        const refresh = localStorage.getItem('refreshToken');
        const realm = localStorage.getItem('realm');
        if (refresh && realm && localStorage.getItem('isLoggedIn')) {
            navigate('/documents');
        }
    }, [navigate]);

    const handleLogin = async (e) => {
        e.preventDefault();
        const validationErrors = validateForm(formData);
        if (Object.keys(validationErrors).length === 0) {
            setLoading(true);
            const loginState = await login(formData);
            setLoading(false);
            if (loginState && !error) {
                onLogin();
                navigate('/dashboard');
            } else {
                setErrors({global: error});
            }
        } else {
            setErrors(validationErrors);
        }
    };
    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const validateForm = (data) => {
        const errors = {};
        if (!data.username) errors.username = 'Username is required';
        if (!data.password) errors.password = 'Password is required';
        if (!data.realm) errors.realm = 'Realm is required';
        return errors;
    };

    return (
        <>
            <CustomSnackbar open={snackbarOpen} message={snackbarMessage}
                            onClose={handleCloseSnackbar}/>
            <div className="modal">
                <div className="left-half">
                    <img src={LogoImage} alt="logo" className="logo"/>
                </div>
                <div className="right-half">
                    <div className="content">
                        <Typography variant="h3">
                            Welcome To
                        </Typography>
                        <img src={LoginImage} alt="login" className="loginText"/>
                        <form id="loginForm" onSubmit={handleLogin}>
                            <TextField
                                fullWidth
                                label="Username"
                                name="username"
                                variant="outlined"
                                margin="normal"
                                value={formData.username}
                                onChange={handleChange}
                                error={!!errors.username}
                                helperText={errors.username}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '&:hover fieldset': {
                                            borderColor: '#432F60',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: '#432F60',
                                        },
                                    },
                                    '& .MuiInputLabel-root': {
                                        '&:hover': {
                                            color: '#432F60',
                                        },
                                        '&.Mui-focused': {
                                            color: '#432F60',
                                        },
                                    },
                                }}
                            />
                            <TextField
                                fullWidth
                                label="Password"
                                name="password"
                                variant="outlined"
                                margin="normal"
                                type={showPassword ? 'text' : 'password'}
                                value={formData.password}
                                onChange={handleChange}
                                error={!!errors.password}
                                helperText={errors.password}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={togglePasswordVisibility}
                                                edge="end"
                                            >
                                                {showPassword ? <Visibility/> : <VisibilityOff/>}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '&:hover fieldset': {
                                            borderColor: '#432F60',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: '#432F60',
                                        },
                                    },
                                    '& .MuiInputLabel-root': {
                                        '&:hover': {
                                            color: '#432F60',
                                        },
                                        '&.Mui-focused': {
                                            color: '#432F60',
                                        },
                                    },
                                }}
                            />
                            <Button type="submit" className="loginbtn"
                                    disabled={loading} // Disable the button while loading
                                    endIcon={loading ? (<CircularProgress size={24} sx={{color: '#fff'}}/>) : null}
                            >
                                {loading ? (<span style={{color: '#fff'}}>Logging In...</span>) : 'SIGN IN'}
                            </Button>
                            {errors.global && <Typography color="error">{errors.global}</Typography>}
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Login;
