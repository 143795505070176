import React, {createContext, useContext, useState, useEffect} from 'react';
import {login as apiLogin, logout as apiLogout} from '../services/authService';
import {useNavigate} from 'react-router-dom';

const AuthContext = createContext();

export const useAuth = () => {
    return useContext(AuthContext);
};

export const AuthProvider = ({children}) => {
    const [user, setUser] = useState(null);
    const [role, setRole] = useState(null);
    const [error, setError] = useState(null);


    useEffect(() => {
        const savedUser = JSON.parse(localStorage.getItem('user'));
        const savedRole = localStorage.getItem('role');
        if (savedUser && savedRole) {
            setUser(savedUser);
            setRole(savedRole);
        }
    }, []);

    const login = async (formData) => {
        try {
            const {
                userRefreshToken,
                userAccessToken,
                realm,
                username,
                userInfo,
                userRole
            } = await apiLogin(formData, setError);
            if (!userInfo) {
                console.error('No user info');
                return false;
            }
            setUser(userInfo);
            if (!userRole) {
                console.error('No user role');
                return false;
            }
            const intersection = userRole.filter(item => ['user', 'maintainer', 'admin'].includes(item));
            if (!intersection) {
                console.error('Login failed:');
                return false;
            }
            let customRole = intersection[0];
            // customRole = 'admin'
            setRole(customRole);
            localStorage.setItem('user', JSON.stringify(userInfo));
            localStorage.setItem('accessToken', userAccessToken);
            localStorage.setItem('refreshToken', userRefreshToken);
            localStorage.setItem('realm', realm);
            localStorage.setItem('username', username);
            localStorage.setItem('role', customRole);
            localStorage.setItem('isLoggedIn', 'true');
            setError(null);
            return true;
        } catch (error) {
            console.error('Login failed');
            setError('Login failed. Please check your credentials.');
            return false;
        }
    };

    const logout = async () => {
        try {
            const logoutStatus = await apiLogout(setError());
            if (logoutStatus) {
                localStorage.clear();
            }
            return false;
        } catch (error) {
            console.error('Logout failed:', error);
            return false;
        }
    };

    const value = {user, role, login, logout, error};

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
};
