import React, {useState, useEffect, useRef} from 'react';
import GridWrapper from '../../components/common/GridWrapper/GridWrapper';
import './Image-grid.css';
import FormControl from '@mui/material/FormControl';
import {
    Select,
    MenuItem,
    TableRow,
    Stack,
    TableContainer,
    TableHead,
    TableBody,
    Table,
    Paper,
    TableFooter, TableCell, CircularProgress
} from '@mui/material';
import {Divider, TextField, Box, IconButton, InputAdornment, Card, CardContent, CardMedia} from '@mui/material';
import Typography from '@mui/material/Typography';
// import DocumentView from '../Doc-Display/Document';
import DisplayDoc from '../Doc-Display/doc-display';
import {useNavbar} from '../../components/Navbar/NavbarContext';
import FileSystemUI from './filesystem-ciew';
import api from "../../API";
import {useAuth} from "../../contexts/AuthContext";
import Pagination from '../../components/Pagination/Pagination';
import CustomSnackbar from '../../components/SnackBar/Snackbar';
import eventEmitter from './EvenEmitter';
import {Chip} from '@mui/material';
import DocumentThumbnail from "../../components/Document/documentThumbnail";
import FilterContent from "../../components/Filters/FilterContent"
import Grid from "@mui/material/Grid";
import {useLocation} from 'react-router-dom';

export default function Dashboard(item) {
    const {user, role} = useAuth();
    const {setCollapsed} = useNavbar();
    setCollapsed(false);
    const [params, setParams] = useState({docIds: []});
    const [selectDoc, setSelectDoc] = useState(null);
    const [redirect, setRedirect] = useState(false);
    const [docs, setDocs] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [previous, setPrevious] = useState(null);
    const [next, setNext] = useState(null);
    const [thumbnail, setThumbnail] = useState(null);
    const [limit, setLimit] = useState(10);
    const [quietMode, setQuietMode] = useState(null);
    const [snackbarOpen, setSnackBarOpen] = React.useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [deleteOpen, setDeleteOpen] = React.useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const location = useLocation();
    const {defaultDocType} = location.state || {};

    const fetchDocs = async (url, params) => {
        try {
            // params.docIds = '';
            setIsLoading(true);
            const response = await api.get(url, {params});
            setIsLoading(false);
            if (response.status !== 200) {
                console.error('Files loading error', response.status);
                return false;
            }
            const responseData = response.data;
            setTotalPages(responseData.count);
            setPrevious(responseData.previous);
            setNext(responseData.next);
            setDocs(responseData.results);
            setThumbnail(responseData.results.map(doc => doc.document_thumbnail));
            return true;
        } catch (error) {
            console.error('Error fetching documents:', error);
            return false;
        }
    };
    window.isFolderType = false;
    window.redirect = false;
    const [isFolderType, setIsFolderType] = useState(
        window.isFolderType || false
    );

    const handleItemView = () => {
        window.isFolderType = false;
        setIsFolderType(window.isFolderType);
        // console.log('isFolderType', isFolderType);
    };
    const handleFolderType = () => {
        window.isFolderType = true;
        setIsFolderType(window.isFolderType);
        // console.log('isFolderType', isFolderType);
    };


    const createParams = (docIds = [], tagsSearch = [], docTypeSearch = [], contentSearch = null) => {
        const query_params = {}
        query_params.docIds = docIds;

        if (docIds.length > 0) {
            query_params.document_ids = docIds.join(',');
        }
        if (tagsSearch.length > 0) {
            query_params.tags = tagsSearch.map(tag => tag.tag_id).join(',');
        }
        if (docTypeSearch.length > 0) {
            query_params.types = docTypeSearch.map(type => type.document_type).join(',');
        }
        if (contentSearch) {
            query_params.content_search = contentSearch;
        }
        query_params.latest = true;
        setParams(query_params);
    };

    useEffect(() => {
        if (!defaultDocType) {
            const query_params = params;
            query_params.limit = limit;
            query_params.offset = (currentPage - 1) * limit;
            setParams(query_params);
            fetchDocs('data/documents', query_params).then(success => setQuietMode(!success));
        }
    }, [params, limit, currentPage]);


    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleItem = (doc) => {
        setSelectDoc(doc);
        setRedirect(true);
    };

    const handleDocBack = () => {
        setRedirect(false);
    };

    const handleDeleteOpen = () => {
        console.log('Opening')
        setDeleteOpen(true);
    };

    const handleDeleteClose = () => {
        console.log('Closing')
        setDeleteOpen(false);
    };


    const deleteDocument = async (doc) => {
        console.log('Delete', doc);
        try {
            const doc_id = doc.document_id;
            const response = await api.delete(`data/documents?document_id=${doc_id}`);

            if (response.status === 200) {
                await fetchDocs('data/documents', params);
                console.log('Document deleted successfully');
                handleDeleteClose()
                showSnackbar('Document Deleted successfully');
            } else {
                console.error('Failed to delete document');
            }
        } catch (err) {
            console.error(err);
        }
    };


    const downloadPdf = async (doc) => {
        console.log('Download', doc);
        try {
            const doc_id = doc.document_id;
            const response = await api.get(`data/documents?document_id=${doc_id}&file=true&download=true`, {
                responseType: 'arraybuffer'
            });
            const fileType = response.headers['content-type'] || 'application/octet-stream';
            const docBlob = new Blob([response.data], {type: fileType});
            const docDataUrl = URL.createObjectURL(docBlob);
            const link = document.createElement('a');
            link.href = docDataUrl;
            link.download = doc.document_super_name + doc.document_version_location.substring(doc.document_version_location.lastIndexOf('.'));
            document.body.appendChild(link);
            link.click();
            URL.revokeObjectURL(docDataUrl);
            document.body.removeChild(link);
            showSnackbar('Document Downloaded successfully');
        } catch (err) {
            console.error(err);
        }
    };

    const handleLimitChange = (event) => {
        setLimit(Number(event.target.value));
        setCurrentPage(1);
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackBarOpen(false);
    };

    const showSnackbar = (message) => {
        setSnackbarMessage(message);
        setSnackBarOpen(true);
    };

    useEffect(() => {
        const handleRedirect = () => {
            setRedirect(false);
        };

        eventEmitter.on('redirectToDashboard', handleRedirect);

        return () => {
            eventEmitter.off('redirectToDashboard', handleRedirect);
        };
    }, []);

    if (redirect) {
        return (
            <GridWrapper>
                <DisplayDoc doc={selectDoc} doc_id={selectDoc.document_id} handleDocBack={handleDocBack}/>
            </GridWrapper>
        );
    } else {
        if (isFolderType) {
            return (
                <GridWrapper>
                    <FilterContent
                        createParams={createParams} showSnackbar={showSnackbar}
                        handleItemView={handleItemView}/>
                    <FileSystemUI docs={docs}
                                  downloadPdf={downloadPdf}
                                  handleItem={handleItem}
                                  handleDeleteOpen={handleDeleteOpen}
                                  deleteOpen={deleteOpen}
                                  handleDeleteClose={handleDeleteClose}
                                  deleteDocument={deleteDocument}/>
                </GridWrapper>
            );
        } else {
            return (
                <TableContainer component={Paper} sx={{width: '85vw', height: '90vh'}}>
                    <Table stickyHeader sx={{height: '100%', width: '100%',}} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <FilterContent
                                        createParams={createParams} showSnackbar={showSnackbar}
                                        handleFolderType={handleFolderType}/>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        {isLoading ? (
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '60vh'
                                }}
                            >
                                <CircularProgress size={48} color="secondary"/>
                            </Box>
                        ) : (
                            <TableBody>
                                <Box display="flex" flexDirection="column" gap={1}>
                                    <Stack direction="row" spacing={1} alignItems="center" marginLeft={2}>
                                        <Typography variant="h5" gutterBottom>Documents:</Typography>
                                        <Typography variant="h6" gutterBottom> {totalPages}</Typography>
                                    </Stack>
                                </Box>
                                <Box className="image-grid" sx={{maxHeight: '90%', overflow: 'auto', marginLeft: 1}}>
                                    {(params.docIds.length > 0 ?
                                            params.docIds
                                                .map(id => docs.find(doc => doc.document_id.toString() === id))
                                                .filter(doc => doc !== undefined) :
                                            docs
                                    ).map(doc => (
                                        <DocumentThumbnail
                                            key={doc.document_id}
                                            role={role}
                                            document={doc}
                                            handleItem={handleItem}
                                            downloadPdf={downloadPdf}
                                            handleDeleteOpen={handleDeleteOpen}
                                            deleteOpen={deleteOpen}
                                            handleDeleteClose={handleDeleteClose}
                                            deleteDocument={deleteDocument}
                                        />
                                    ))}
                                </Box>
                                <CustomSnackbar open={snackbarOpen} message={snackbarMessage}
                                                onClose={handleCloseSnackbar}/>
                            </TableBody>
                        )}
                        <TableFooter sx={{
                            position: 'sticky',
                            bottom: 0,
                            backgroundColor: (theme) => theme.palette.background.paper,
                            zIndex: 1000,
                        }}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                marginBottom: 2,
                                mt: 2,
                                ml: 2,
                                flexShrink: 0
                            }}>
                                <Typography sx={{marginRight: 1, fontSize: '0.875rem', fontWeight: 'bold'}}>Per
                                    Page:</Typography>
                                <FormControl variant="outlined" size="small"
                                             sx={{
                                                 minWidth: 80,
                                                 marginRight: 2,
                                                 '& .MuiOutlinedInput-root': {
                                                     '& fieldset': {
                                                         borderColor: '#674f87',
                                                     },
                                                     '&:hover fieldset': {
                                                         borderColor: '#ef798a',
                                                     },
                                                     '&.Mui-focused fieldset': {
                                                         borderColor: '#ef798a',
                                                     },
                                                 },
                                             }}
                                >
                                    <Select
                                        labelId="limit-label"
                                        id="limit"
                                        value={limit}
                                        onChange={handleLimitChange}
                                        sx={{fontSize: '1rem'}}
                                    >
                                        <MenuItem value={10}>10</MenuItem>
                                        <MenuItem value={20}>20</MenuItem>
                                        <MenuItem value={50}>50</MenuItem>
                                    </Select>
                                </FormControl>
                                <Pagination currentPage={currentPage} totalPages={totalPages}
                                            onPageChange={handlePageChange}
                                            limit={limit}/>
                            </Box>
                        </TableFooter>
                    </Table>
                </TableContainer>
            );
        }
    }
};